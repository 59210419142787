import { combineReducers } from "redux";

import authData from "./authReducer";
import userData from "./userReducer";
import serviceData from "./serviceReducer";
import loaderData from "./loaderReducer";
import userServiceList from "./userServiceReducer";
import otherProviderDetails from "./otherProviderReducer";
import myPageDetails from "./myPageReducer";
import myBookingDetails from "./myBookingReducer";
import upcomingEventReducer from "./upcomingEventReducer";
import hireNcollaborateReducer from "./hireNcollaborateReducer";
import walletReducer from "./walletReducer";
import calenderReducer from "./calenderReducer";
import eventReducer from "./eventReducer";
import scheduleReducer from "./scheduleReducer";
import saveReducer from "./saveReducer";
import claimReducer from "./claimReducer";
import versionReducer from "./versionReducer";
import notificationReducer from "./notificationReducer";
import paymentReducer from "./paymentReducer";
import paymentHistoryReducer from "./paymentHistoryReducer";
import myEventReducer from "./myEventReducer";
import refDescountReducer from "./refDescountReducer";
import supportReducer from "./supportReducer";
import discountReducer from "./discountReducer";
import myWalletCountersReducer from "./getWallet";

const rootReducer = combineReducers({
  auth: authData,
  user: userData,
  service: serviceData,
  loader: loaderData,
  userServiceList: userServiceList,
  otherProviderDetails: otherProviderDetails,
  myPageDetails: myPageDetails,
  myBookingDetails: myBookingDetails,
  upComingEvents: upcomingEventReducer,
  hireNcollaborate: hireNcollaborateReducer,
  wallet: walletReducer,
  calendar: calenderReducer,
  event: eventReducer,
  schedule: scheduleReducer,
  save: saveReducer,
  claim: claimReducer,
  version: versionReducer,
  notifications: notificationReducer,
  payment: paymentReducer,
  paymentHistory: paymentHistoryReducer,
  myEvent: myEventReducer,
  support: supportReducer,
  refDescount: refDescountReducer,
  discountCode: discountReducer,
  myWalletCounters: myWalletCountersReducer 
});

export default rootReducer;
